<template>
  <main>
    <ThisComponent
      text-enter-email="Введи свой email"
      text-enter-code="Введи код из письма, отправленного на почтовый ящик"
    >
      <template #default>
        <h1>Личный кабинет</h1>
        <p>Добро пожаловать на новогоднюю ярмарку «Сбудется», где ты сможешь найти для себя счастье и новые возможности.</p>
        <hr>
      </template>
      <template #auth-error>
        <div class="alert__heading">Ошибка авторизации</div>
        <p>Если ты сотрудник ПБК, создай задачу в Jira: IT PBK &ndash; NY Market,<br>
        если ты сотрудник НБН &ndash; напиши <a href="mailto:напиши yana.burlet@MegaFon.ru">yana.burlet@MegaFon.ru</a></p>
      </template>
    </ThisComponent>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationEmailCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  main {
    margin: auto auto 0;
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
