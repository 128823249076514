<template>
  <form class="form form--auth-email-code" v-on:hide-errors="hideErrors">
    <slot></slot>
    <div class="alert alert--danger" v-if="error">
      <slot name="auth-error">
        <div v-html="error"></div>
      </slot>
    </div>
    <div class="step" v-if="step === 'email'">
      <p>{{ textEnterEmail }}</p>
      <div class="step__fields">
        <div class="form-group">
          <input
            type="email"
            class="form-field form-field--large js-email"
            placeholder="Ваш email"
            v-model="credentials.email"
          >
        </div>
        <button class="button button--rounded button--large" @click.prevent="getCode()" :disabled="sending">Получить&nbsp;код&nbsp;&rarr;</button>
      </div>
    </div>
    <div class="step" v-if="step === 'code'">
      <p>{{ textEnterCode }}</p>
      <div class="step__fields">
        <div class="form-group">
          <input
            type="tel"
            ref="code"
            pattern="\d*"
            class="form-field form-field--large"
            placeholder="Код"
            v-model="credentials.code"
            maxlength="6"
          >
        </div>
        <button class="button button--rounded button--large" @click.prevent="submit()" :disabled="sending">Войти</button>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import auth from '../api/auth-email-pass'
import MaskedInput from 'vue-text-mask'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'

Vue.component('masked-input', MaskedInput)

export default {
  props: {
    textEnterEmail: {
      type: String,
      default: 'Введите ваш email'
    },
    textEnterCode: {
      type: String,
      default: 'Введите код из письма, отправленного на почтовый ящик'
    }
  },
  data () {
    return {
      step: 'email',
      credentials: {
        email: '',
        code: ''
      },
      sending: false,
      error: ''
    }
  },
  methods: {
    getCode () {
      const credentials = {
        email: this.credentials.email
      }

      this.error = ''

      if (utils.validateEmail(credentials.email)) {
        Vue.$logger.info('AuthorizationEmailCode | auth.getCode | credentials: ', credentials)

        this.sending = true

        auth.getCode(this.$store, credentials)
          .then((response) => {
            this.sending = false
            this.step = 'code'
          }).catch((error) => {
            this.sending = false

            this.$emit('hide-errors')
            this.error = appError.getMessage(error.message)

            Vue.$logger.debug('AuthorizationEmailCode | auth.getCode | ошибка: ', error)
          })
      } else {
        this.$emit('hide-errors')
        this.error = appError.getMessage('wrong_email_format')
      }
    },
    submit () {
      const credentials = {
        email: this.credentials.email,
        code: this.credentials.code
      }

      this.error = ''

      if (!utils.validateEmail(credentials.email)) {
        this.$emit('hide-errors')
        this.error = 'Неверный формат почты'
      } else if (credentials.code.length === 0) {
        this.$emit('hide-errors')
        this.error = 'Введите код'
      } else {
        Vue.$logger.info('AuthorizationEmailCode | auth.login | credentials: ', credentials)

        this.sending = true

        auth.login(this.$store, credentials)
          .then((response) => {
            this.sending = false

            const data = response.data
            let role

            try {
              const payload = JSON.parse(data.token.split('.').map(part => {
                return utils.decodeBase64(part)
              })[1])

              role = payload.role
            } catch (error) {
              role = 'user'
            }

            this.$store.commit('user/authenticate')

            this.$store.dispatch('user/login', { data, role }).then((response) => {
              Vue.$logger.debug('AuthorizationEmailCode | authorized')

              const urlParams = new URLSearchParams(window.location.search)
              if (urlParams.get('token') !== null) {
                location.href = '/#/cabinet'
              } else {
                this.$router.push('/cabinet').catch(()=>{})
              }
            })
          }).catch((error) => {
            this.sending = false

            this.$emit('hide-errors')
            this.error = appError.getMessage(error.message)

            Vue.$logger.debug('AuthorizationEmailCode | auth.login | ошибка: ', error)
          })
      }
    },
    checkFocus () {
      if (this.step === 'email') {
        document.querySelector('.js-email').focus()
      } else if (this.step === 'code') {
        this.$refs.code.focus()
      }
    },
    hideErrors () {
      this.error = ''
    },
    afterMounted () {
      this.checkFocus()
    }
  },
  updated () {
    this.checkFocus()
  }
}
</script>

<style lang="css" scoped>
  .form {
    margin-bottom: 20px;
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
