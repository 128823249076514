const module = {
  messages: {
    server_error: {
      heading: 'Ошибка сервера'
    },
    network_error: {
      heading: 'Ошибка соединения с сервером',
      text: '<p>Проверьте ваше соединение или попробуйте ещё раз позднее.</p>'
    },
    general_error: {
      heading: 'Ошибка'
    },
    action_not_found: {
      heading: 'Ошибка обращения к ресурсу'
    },
    auth_error: {
      heading: 'Ошибка авторизации',
      text: '<p>Во время авторизации произошла ошибка.<br> Попробуйте ещё раз позднее.</p>'
    },
    wrong_phone_format: {
      heading: 'Неверный формат номера телефона',
      text: '<p>Введите номер телефона в формате PHONE_EXAMPLE.</p>'
    },
    wrong_sms_code: {
      heading: 'Неверный код',
      text: '<p>Введите корректный код из SMS.</p>'
    },
    not_authorized: {
      heading: 'Ошибка доступа',
      text: '<p>Время вашей сессии истекло или вы не авторизованы.<p>Чтобы заново авторизоваться перейдите на форму авторизации, нажав кнопку ниже.</p><a class="button button--large" href="/#/auth">Авторизоваться</a>'
    },
    wrong_email_format: {
      heading: 'Неверный формат электронной почты',
      text: '<p>Введите email в формате user@company.ru</p>'
    },
    wrong_token: {
      heading: 'Неверный токен'
    },
    no_token: {
      heading: 'Токен не указан'
    },
    catalog_error: {
      heading: 'Ошибка получения каталога продуктов'
    },
    order_error: {
      heading: 'Ошибка при создании заказа',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    verification_error: {
      heading: 'Ошибка отправки данных формы подтверждения'
    },
    upload_error: {
      heading: 'Ошибка загрузки файлов'
    },
    act_error: {
      heading: 'Ошибка получения акта'
    },
    act_not_found: {
      heading: 'Акт не найден'
    },
    users_get_error: {
      heading: 'Ошибка получения списка пользователей'
    },
    users_get_csv_error: {
      heading: 'Ошибка получения CSV-файла списка пользователей'
    },
    user_activate_error: {
      heading: 'Ошибка активирования пользователя'
    },
    user_activate_cannot_if_admin: {
      heading: 'Ошибка активирования пользователя',
      text: '<p>Изменение активности пользователя с ролью Admin запрещено</p>'
    },
    user_deactivate_error: {
      heading: 'Ошибка деактивирования пользователя'
    },
    user_deactivate_cannot_if_admin: {
      heading: 'Ошибка активирования пользователя',
      text: '<p>Изменение активности пользователя с ролью Admin запрещено</p>'
    },
    tasks_get_error: {
      heading: 'Ошибка получения списка заданий'
    },
    task_not_found: {
      heading: 'Задание не найдено',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    task_approve_error: {
      heading: 'Ошибка при попытке подтвердить выполнение задания',
      text: ''
    },
    invalid_task_id: {
      heading: 'Некорректный номер задачи на подтверждение',
      text: ''
    },
    task_must_have_reviewing_status_to_be_approved: {
      heading: 'Подтверждаемая задача находится не в статусе reviewing',
      text: ''
    },
    form_task_not_found: {
      heading: 'Не найдена задача на загрузку документов',
      text: ''
    },
    act_tasks_must_not_be_approved_before_form_tasks: {
      heading: 'Сначала подтвердите задачу на загрузку документов',
      text: ''
    },
    task_decline_error: {
      heading: 'Ошибка при попытке отменить задание',
      text: ''
    },
    task_already_reviewed: {
      heading: 'Ошибка при попытке взять задание в работу',
      text: ''
    },
    task_review_error: {
      heading: 'Ошибка при попытке взять задание в работу'
    },
    order_not_found: {
      heading: 'Заказ не найден',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    order_get_error: {
      heading: 'Ошибка при попытке получить информацию о заказе',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    order_cancel_error: {
      heading: 'Ошибка при попытке отменить заказ',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    order_cancel_period_not_active: {
      heading: 'Ошибка при попытке отменить заказ',
      text: '<p>Период должен быть активен.</p>'
    },
    order_cancel_wrong_status: {
      heading: 'Ошибка при попытке отменить заказ',
      text: '<p>Заказ должен быть в статусе Ожидает подтверждения.</p>'
    },
    order_cancel_transaction_already_canceled: {
      heading: 'Ошибка при попытке отменить заказ',
      text: '<p>Транзакция по заказу уже была отменена.</p>'
    },
    orders_get_error: {
      heading: 'Ошибка получения списка заказов',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    orders_get_csv_error: {
      heading: 'Ошибка получения CSV-файла списка заказов'
    },
    transactions_get_error: {
      heading: 'Ошибка получения списка транзакций'
    },
    transactions_get_csv_error: {
      heading: 'Ошибка получения CSV-файла списка транзакций'
    },
    files_size_exceeded: {
      heading: 'Превышен лимит на размер файлов',
      text: '<p>Вы превысили лимит на размер загружаемых файлов. Уменьшите их размер и загрузите снова.</p>'
    },
    user_not_found: {
      heading: 'Пользователь не найден',
      text: '<p>Обратитесь в службу поддержки.</p>'
    },
    user_tasks_get_error: {
      heading: 'Ошибка получения списка заданий пользователя'
    },
    site_settings_get_error: {
      heading: 'Ошибка получения списка пользователей'
    },
    insufficent_funds: {
      heading: 'Недостаточно баллов',
      text: '<p>Вашего баланса недостаточно для оформления заказа.<br> Выберите другой продукт или номинал.</p>'
    },
    spend_all_points: {
      heading: 'Потрачены не все баллы',
      text: '<p>Необходимо потратить все баллы для оформления заказа.<br> Выберите другой продукт или номинал</p>'
    },
    documents_upload_is_not_active: {
      heading: 'Загрузка документов приостановлена',
      text: '<p>Документы принимаются с {from} до {to}</p>'
    },
    default: {
      heading: 'Ошибка',
      text: '<p>Обратитесь в службу поддержки или попробуйте ещё раз позднее.</p>'
    }
  },
  getMessage: (errorCode, overrideHeading, overrideText) => {
    const heading = overrideHeading || module.messages[errorCode].heading || module.messages.default.heading
    const text = overrideText || module.messages[errorCode].text || module.messages.default.text

    const error = Object.assign(module.messages[errorCode], { heading, text })

    return module.template.replace(/{{heading}}/gi, error.heading).replace(/{{text}}/gi, error.text)
  },
  template: '<div class="alert__heading">{{heading}}</div>{{text}}'
}

export default module
